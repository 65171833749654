import styled from "styled-components";
import { EntityTypeRisk } from "types";

type RiskStyledProps = {
  riskLevel: EntityTypeRisk;
};

export const RiskPill = styled.div<RiskStyledProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 7px 4px 12px;
  border-radius: 24px;

  ${({ riskLevel, theme }) => {
    const colors: Record<EntityTypeRisk, string> = {
      LOW: theme.colors?.success,
      MEDIUM: theme.colors?.orange,
      HIGH: theme.colors?.red,
      VERY_HIGH: theme.colors?.darkRed,
    };

    return `
      background-color: ${colors[riskLevel]};
      color: white;
    `;
  }}
`;

export const RiskText = styled.span`
  font-weight: 500;
  font-size: 14px;
`;
