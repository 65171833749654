import { useState } from "react";
import { Business } from "ui";
import * as S from "./styled";

export type AvatarProps = {
  size: "extraSmall" | "small" | "average" | "normal" | "large";
  shape?: "circle" | "square";
  src: string;
};

const Avatar = ({ src, size = "normal", shape = "circle" }: AvatarProps) => {
  const [hasError, setHasError] = useState(false);

  return (
    <S.Avatar onError={() => setHasError(true)} src={hasError ? "" : src} size={size} shape={shape}>
      {hasError && <Business sx={{ width: "65%", height: "65%" }} />}
    </S.Avatar>
  );
};
export default Avatar;
