import RiskIndicator from "components/RiskIndicator";
import { EntityTypeRisk } from "types";
import { Grid, Title } from "ui";

type Props = {
  address: string;
  truncate?: boolean;
  riskLevel: EntityTypeRisk;
};

const renderAddress = (address: string, truncate: boolean) => {
  if (truncate) {
    return (
      <Title>
        {address.substring(0, length + 5)}...
        {address.substring(address.length - 5)}
      </Title>
    );
  } else {
    return <Title>{address}</Title>;
  }
};

export const Address = ({ address, truncate = true, riskLevel }: Props) => (
  <Grid item xs={12} container alignItems="center" columnSpacing={1}>
    <Grid container xs="auto" flexWrap={"nowrap"} gap={"12px"} alignItems={"center"} paddingLeft={1}>
      <Title>{renderAddress(address, truncate)}</Title>
      <RiskIndicator riskLevel={riskLevel} />
    </Grid>
  </Grid>
);

export default Address;
