import { Columns, Grid, Tooltip } from "ui";
import { TokenTags } from "../TokensTags";
import { NationFlag, EventTag } from "components";
import { Badge } from "@mui/material";

const getColumns = (): Columns => [
  {
    field: "Date",
    headerName: "Date",
    width: 120,
  },
  {
    field: "Subject",
    headerName: "Subject",
    width: 350,
    renderCell: (params) => <b>{params.value}</b>,
  },
  {
    field: "Type",
    headerName: "Type",
    width: 190,
  },
  {
    field: "Sub-Type",
    headerName: "Sub-Type",
    width: 190,
    renderCell: (params) => <EventTag type="Sanction" label={params.value} />,
  },

  {
    field: "Jurisdiction",
    headerName: "Jurisdiction",
    width: 190,
    renderCell: (params) => {
      const countries: string[] = Array.isArray(params.value) ? params.value : [params.value];

      if (!countries || countries.length === 0) {
        return <Grid item>No countries listed</Grid>;
      }

      // Extract the primary country and count additional countries
      const primaryCountry = countries[0];
      const additionalCountriesCount = countries.length - 1;

      const MoreIndicator = () => (
        <Tooltip title={countries.slice(1).join(", ")} placement="top">
          <Badge badgeContent={`+${additionalCountriesCount}`} color="primary" />
        </Tooltip>
      );

      return (
        <Grid item container flexWrap="nowrap" columnGap={1}>
          <Grid item>
            <NationFlag countryName={primaryCountry} />
          </Grid>
          <Grid container columnGap={3}>
            <Grid item>{primaryCountry}</Grid>
            <Grid>{additionalCountriesCount > 0 && <MoreIndicator />}</Grid>
          </Grid>
        </Grid>
      );
    },
  },
  {
    field: "Enforcement Body",
    headerName: "Enforcement Body",
    width: 190,
  },
  {
    field: "Tokens",
    headerName: "Tokens",
    flex: 1,
    renderCell: (params) => {
      return <TokenTags tokens={params.value} />;
    },
  },
];

export default getColumns;
